import { WorkCardData } from 'entities/work'

import loveCakeFirst from '../../shared/assets/love-cake-1.jpg'
import loveCakeSecond from '../../shared/assets/love-cake-2.jpg'
import loveCakeThird from '../../shared/assets/love-cake-3.jpg'
import loveCakeFourth from '../../shared/assets/love-cake-4.jpg'
import flowersCake from '../../shared/assets/flowers-cake.jpg'
import cupcakesCake from '../../shared/assets/cupcakes-cake.jpg'
import medovikCake from '../../shared/assets/medovik-cake.jpg'
import happyBirthdayCake from '../../shared/assets/happy-birthday-cake.jpg'
import happyBirthdayDigitCake from '../../shared/assets/happy-birthday-cake-digit.jpg'
import CupcakesRedVelvet from '../../shared/assets/cupcakes-red-velvet-1.jpg'
import CupcakesRedVelvetFirst from '../../shared/assets/cupcakes-red-velvet-2.jpg'
import CupcakesRedVelvetSecond from '../../shared/assets/cupcakes-red-velvet-3.jpg'
import CupcakesRedVelvetThird from '../../shared/assets/cupcakes-red-velvet-4.jpg'
import DecorCakeFirst from '../../shared/assets/decor-flowers-cake-1.jpg'
import DecorCakeSecond from '../../shared/assets/decor-flowers-cake-2.jpg'
import MinecraftCake from '../../shared/assets/minecraft-cake.jpg'
import GenderCakeFirst from '../../shared/assets/gender-cake-1.jpg'
import GenderCakeSecond from '../../shared/assets/gender-cake-2.jpg'
import GirlCakeFirst from '../../shared/assets/girl-cake-1.jpg'
import GirlCakeSecond from '../../shared/assets/girl-cake-2.jpg'
import happyBirthdayCakeLambetFirst from '../../shared/assets/happy-birthday-cake-lambet-1.jpg'
import happyBirthdayCakeLambetSecond from '../../shared/assets/happy-birthday-cake-lambet-2.jpg'
import CupcakesSecond from '../../shared/assets/cupcakes.jpg'
import christmasBallsFirst from '../../shared/assets/сhristmas-balls-1.jpg'
import christmasBallsSecond from '../../shared/assets/сhristmas-balls-2.jpg'
import christmasBallsThird from '../../shared/assets/сhristmas-balls-3.jpg'
import christmasBallsFourth from '../../shared/assets/сhristmas-balls-4.jpg'
import blackCakeFirst from '../../shared/assets/black-cake-1.jpg'
import blackCakeSecond from '../../shared/assets/black-cake-2.jpg'
import blackCakeThird from '../../shared/assets/black-cake-3.jpg'
import roseCakeFirst from '../../shared/assets/rose-cake-1.jpg'
import roseCakeSecond from '../../shared/assets/rose-cake-2.jpg'
import roseCakeThird from '../../shared/assets/rose-cake-3.jpg'
import valentineFirst from '../../shared/assets/valentine-1.jpg'
import valentineSecond from '../../shared/assets/valentine-2.jpg'

import ameliaCakeFirst from '../../shared/assets/amelia-cake-1.jpg'
import ameliaCakeSecond from '../../shared/assets/amelia-cake-2.jpg'
import ameliaCakeThird from '../../shared/assets/amelia-cake-3.jpg'

import arrowCakeFirst from '../../shared/assets/arrow-cake-1.jpg'
import arrowCakeSecond from '../../shared/assets/arrow-cake-2.jpg'

import elkinCakeFirst from '../../shared/assets/elkin-cake-1.jpg'
import elkinCakeSecond from '../../shared/assets/elkin-cake-2.jpg'
import elkinCakeThird from '../../shared/assets/elkin-cake-3.jpg'

import greenCakeFirst from '../../shared/assets/green-cake-1.jpg'
import greenCakeSecond from '../../shared/assets/green-cake-2.jpg'

import harryPotterFirst from '../../shared/assets/harry-potter-1.jpg'
import harryPotterSecond from '../../shared/assets/harry-potter-2.jpg'
import harryPotterThird from '../../shared/assets/harry-potter-3.jpg'

import redCakeFirst from '../../shared/assets/red-cake-1.jpg'
import redCakeSecond from '../../shared/assets/red-cake-2.jpg'
import redCakeThird from '../../shared/assets/red-cake-3.jpg'

import redCakeTwoFirst from '../../shared/assets/red-cake-2-1.jpg'
import redCakeTwoSecond from '../../shared/assets/red-cake-2-2.jpg'
import redCakeTwoThird from '../../shared/assets/red-cake-2-3.jpg'

import burgerCakeFirst from '../../shared/assets/burger-cake-1.jpg'
import burgerCakeSecond from '../../shared/assets/burger-cake-2.jpg'
import burgerCakeThird from '../../shared/assets/burger-cake-3.jpg'

export const workSlideDataList: WorkCardData[] = [
    {
        images: [
            { src: loveCakeFirst, placeholder: 'RUKKH3*IRjMd' },
            { src: loveCakeSecond, placeholder: 'RdJiw]LzTJVY' },
            { src: loveCakeThird, placeholder: 'RJKIMAQ7HCf*' },
            { src: loveCakeFourth, placeholder: 'RCJHjq-;?^4U' },
        ],
        description: 'Love Cake',
    },
    {
        images: [
            { src: blackCakeFirst, placeholder: 'RXL#8^~q%M9F' },
            { src: blackCakeSecond, placeholder: 'RNKBRB~p_3D%' },
            { src: blackCakeThird, placeholder: 'RYMaU{~p-:D%' },
        ],
        description: 'Ламбет с бантами',
    },
    {
        images: [
            { src: cupcakesCake, placeholder: 'RCF}_.^%0Lbe' },
            { src: medovikCake, placeholder: 'RFJaAk_N-;tS' },
        ],
        description:
            'Порционные десерты (открытый медовик с ягодами и трайфлы Oreo, ваниль/клубника)',
    },
    {
        images: [
            { src: burgerCakeSecond, placeholder: 'RAJklw_N%#Mc' },
            { src: burgerCakeFirst, placeholder: 'RVKUG]%2?wNw' },
        ],
        description: '3D торт "Бургер"',
    },
    {
        images: [{ src: flowersCake, placeholder: 'RGGlU}_4D+t8' }],
        description: 'Торт с декором из вафельной бумаги и живыми цветами',
    },
    {
        images: [
            { src: roseCakeFirst, placeholder: 'R5HewV0L0LH=' },
            { src: roseCakeSecond, placeholder: 'RHH{A-DNS6IA' },
            { src: roseCakeThird, placeholder: 'R7J[O{UukrIU' },
        ],
        description: 'White rose',
    },

    {
        images: [
            { src: greenCakeSecond, placeholder: 'RKHC7d=}_NyC' },
            { src: greenCakeFirst, placeholder: 'RWKe7e^+_4NF' },
        ],
        description: 'Медовик с ягодами',
    },
    {
        images: [
            { src: elkinCakeSecond, placeholder: 'RPJRXI%L?wkX' },
            { src: elkinCakeFirst, placeholder: 'RMJ[L{-;.ANH' },

            { src: elkinCakeThird, placeholder: 'RFKxST%g%%I9' },
        ],
        description: 'Торт с фото на сахарной бумаге "полёт нормальный!"',
    },
    {
        images: [
            { src: harryPotterSecond, placeholder: 'RVO3%$?v?^D*' },
            { src: harryPotterFirst, placeholder: 'RBLqUy.TyWDO' },
            { src: harryPotterThird, placeholder: 'RJD8?~t50eM{' },
        ],
        description: 'Торт на день рождения "Harry Potter"',
    },
    {
        images: [
            { src: ameliaCakeThird, placeholder: 'RWIrEI~q?bxu' },
            { src: ameliaCakeFirst, placeholder: 'RMM7x=-;?wM{' },
            { src: ameliaCakeSecond, placeholder: 'RGLN=7j??w%M' },

        ],
        description: 'Ламбет с бантами',
    },
    {
        images: [
            { src: redCakeThird, placeholder: 'RTI=AIt7_Nxa' },
            { src: redCakeFirst, placeholder: 'RHJRdT-;_NV@' },
            { src: redCakeSecond, placeholder: 'RLJRgb-p_NRP' },

        ],
        description: 'Торт c рюшами на день рождения',
    },
    {
        images: [
            { src: arrowCakeFirst, placeholder: 'RDKnex_4yFxu' },
            { src: arrowCakeSecond, placeholder: 'RIMj,N?v.Ajw' },
        ],
        description: 'Бенто-торт',
    },
    {
        images: [
            { src: valentineSecond, placeholder: 'RKODaz_2.mWA' },
            { src: valentineFirst, placeholder: 'RDLN-}?b%%oe' },

        ],
        description: 'Торт на день Святого Валентина',
    },
    {
        images: [
            { src: GenderCakeFirst, placeholder: 'RAG*{6Tf0%4U' },
            { src: GenderCakeSecond, placeholder: 'RAIq}poy~oIY' },
        ],
        description: 'Торт на гендер–пати',
    },
    {
        images: [
            { src: GirlCakeSecond, placeholder: 'RDI=ADgj%%4o' },
            { src: GirlCakeFirst, placeholder: 'RLJu4E_2?wo#' },
        ],
        description: 'Торт на день рождения',
    },
    {
        images: [{ src: happyBirthdayCake, placeholder: 'R8Lqa+yZXr4m' }],
        description: 'Торт на день рождения ребёнка',
    },
    {
        images: [{ src: CupcakesSecond, placeholder: 'R8KdF{8_ys^%' }],
        description: 'Капкейки с клубникой',
    },
    {
        images: [
            { src: CupcakesRedVelvet, placeholder: 'R1HVYq~q.9l.' },
            { src: CupcakesRedVelvetFirst, placeholder: 'RDI#fK9E~qR5' },
            { src: CupcakesRedVelvetSecond, placeholder: 'R9Gum?01Iq.8' },
            { src: CupcakesRedVelvetThird, placeholder: 'RBEoxrI-4m-=' },
        ],
        description: 'Трайфлы: красный бархат и сникерс',
    },
    {
        images: [
            { src: redCakeTwoThird, placeholder: 'RVN0#X_N?^RP' },
        ],
        description:'Бенто-торт "love you"',
    },
    {
        images: [
            { src: happyBirthdayCakeLambetFirst, placeholder: 'RGM7u*%h.AIT' },
            { src: happyBirthdayCakeLambetSecond, placeholder: 'R4H.Tf?wA4~q' },
        ],
        description: 'Торт в стиле ламбет',
    },
    {
        images: [{ src: MinecraftCake, placeholder: 'RSIY%^?d-?RN' }],
        description: 'Шоу–торт бомба «Minecraft»',
    },
    {
        images: [{ src: happyBirthdayDigitCake, placeholder: 'RHLN##~WtURi' }],
        description: 'Торт на четыре годика',
    },
    {
        images: [
            { src: redCakeTwoFirst, placeholder: 'RUM7S@?v.mVs' },
            { src: redCakeTwoSecond, placeholder: 'RONc{A?^%~Hs' },
            { src: redCakeTwoThird, placeholder: 'RVN0#X_N?^RP' },
        ],
        description:'Бенто-торт',
    },
    {
        images: [
            { src: christmasBallsSecond, placeholder: 'RKIOd~x@?^-;' },
            { src: christmasBallsThird, placeholder: 'REH_*-Mv.S4T' },
            { src: christmasBallsFirst, placeholder: 'RBHx?4?w.mDO' },
            { src: christmasBallsFourth, placeholder: 'REI=c9?w.98_' },
        ],
        description:
            'Шоколадные шарики с новогодними пожеланиями доступны к заказу',
    },
    {
        images: [
            { src: DecorCakeFirst, placeholder: 'RNJa=Y~p?v.7' },
            { src: DecorCakeSecond, placeholder: 'RUJa.M~px]-o' },
        ],
        description: 'Торт с декором из живых цветов',
    },
]
